
import {Component, Vue} from "vue-property-decorator";
import OrderLineGroupByListing from "@pazion/pazion-catalog/src/views/orders/OrderLineGroupByListing.vue";
import {timestampToYMD, formatDate, formatDateYMD} from "@pazion/pazion-catalog/src/helpers/format";
import PazTooltipButton from "@pazion/pazion-core/src/components/controls/PazTooltipButton.vue";
import PazOrderStatus from "@pazion/pazion-catalog/src/components/order/PazOrderStatus.vue"

@Component({
	components: {
	  OrderLineGroupByListing,
		// OrderLineCancel,
		PazTooltipButton,
		PazOrderStatus,
		// OrderLineRetry
	},
	methods: {
		timestampToYMD,formatDate,formatDateYMD
	}
})
export default class AppOrderList extends Vue {
    private $orderListingType: string | undefined;
    private localTypeStatus: string | undefined = 'combo';

	public headers = [
		{
			text: "OrderId",
			align: "left",
			class: "cell-truncate",
			cellClass: "nowrap",
			sortable: true,
			value: "orderId",
		},
		{
			text: "Orderline",
			align: "left",
			class: "cell-truncate",
			sortable: true,
			cellClass: "nowrap",
			value: "orderLineExternalId",
			groupable: false,
		},
		{
			text: " ",
			align: "left",
			class: "cell-truncate",
			sortable: true,
			cellClass: "nowrap",
			value: null,
			groupable: false,
		},
    {
      text: "Business / Product",
      align: "left",
      class: "cell-truncate",
      sortable: true,
      cellClass: "nowrap",
      value: "orderLineDescription",
      groupable: false,
    },
		{
			text: "City",
			align: "left",
			class: "cell-truncate",
			sortable: true,
			cellClass: "nowrap",
			value: "orderCity",
			groupable: false,
		},
    {
        text: "Order date",
        align: "left",
        class: "cell-truncate text-left",
        sortable: true,
        value: "orderDate",
        groupable: false,
    },
    {
        text: "Delivery date",
        align: "left",
        class: "cell-truncate text-left",
        sortable: true,
        value: "orderDeliveryDate",
        groupable: false,
    },

    {
        text: "Status",
        align: "right",
        class: "cell-truncate text-right",
        sortable: true,
        value: "status",
        groupable: false,
    },
    {
        text: "Total",
        align: "right d-none d-xs-table-cell d-lg-table-cell",
        class: "cell-truncate text-right	mx-0",
        sortable: true,
        value: "orderLineTotal",
        groupable: false,
    },
		{
			text: "Actions",
			align: "right",
			class: "cell-truncate text-right mx-5",
			cellClass: "nowrap",
			sortable: false,
			value: "actions",
			groupable: false,
		}
	];

    mounted() {
        this.localTypeStatus = this.$orderListingType;
    }

}



