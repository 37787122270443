import {addRoute,addRoutes} from "@pazion/pazion-core/src/router";
import "@pazion/pazion-core/src/config";
import {getCatalogRoutes} from "@pazion/pazion-catalog/src/module-config";
import AppOrderList from "./views/AppOrderList.vue";

addRoutes(getCatalogRoutes());

addRoute({
    path: '/orders',
    name: 'orders',
    component: AppOrderList,
    meta: {
        'show-in-nav': true,
        profilePermission: 'orderManagement.show',
        roles: ['user'],
        sort: -70
    },
});
