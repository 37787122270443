
import {Component, Vue, Watch} from "vue-property-decorator";
import PazLoader from '@pazion/pazion-core/src/components/utils/PazLoader.vue';
import PazFooter from '@pazion/pazion-core/src/components/layout/PazFooter.vue';
import PazNavigation from '@pazion/pazion-core/src/components/layout/PazNavigation.vue';
import PazSettingsDrawer from '@pazion/pazion-core/src/components/layout/PazSettingsDrawer.vue';
import {authentication} from '@pazion/pazion-core/src/store';


@Component({
  components: {
    PazNavigation,
    PazSettingsDrawer,
    PazFooter,
    PazLoader
  },
})
export default class PazionApp extends Vue {
  public showNavigation = false;
  public showDrawer = true;

  public created() {
    authentication.verifyUser();
    this.showNavigation = authentication.isAuthenticated;
  }

  get isUserLoggedIn() {
    return authentication.isAuthenticated;
  }

  get isLoading() {
    if (!authentication.isAuthenticated) {
      return false;
    }
    return false;
  }

  get hasDrawer() {
    return this.$route.meta?.hasDrawer
  }

  @Watch('isUserLoggedIn')
  private changeNavigation(authenticated: boolean) {
    this.showNavigation = authenticated;
  }

  get doShowNavigation() {
    return this.showNavigation && !this.$route.meta.hideHeader;
  }

}//
