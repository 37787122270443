import Vue from 'vue';
import Vuex from 'vuex';

import './projectConfig';
import App from './App.vue';

import * as dayjs from 'dayjs'

const timezone = require("dayjs/plugin/timezone");
const utc = require("dayjs/plugin/utc");
require('dayjs/locale/en');

import router, { removeRouteByName, resetRouter } from '@pazion/pazion-core/src/router';
import store from '@pazion/pazion-core/src/store/index';
import 'vue-material-design-icons/styles.css';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import { config } from 'vuex-module-decorators';
import { setConfigSettings } from "@pazion/pazion-core/src/services/settings_config-service";
import RollbarPlugin from "@pazion/pazion-core/src/plugins/Rollbar.plugin";

// Set rawError to true by default on all @Action decorators
config.rawError = true;

dayjs.extend(timezone);
dayjs.extend(utc)
dayjs.locale('en');

import './assets/scss/main.scss'

setConfigSettings(Vue).then(function(){
    Vue.use(Vuex);
    Vue.use(VueRouter);
    Vue.use(RollbarPlugin);
    new Vue({
        vuetify,
        router,
        store,
        render: (h) => h(App),
    }).$mount('#app');
});


